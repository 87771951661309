import React from "react";
import { createRoot } from "react-dom/client";
import "./index.scss";
import App from "./App";
import { store } from "../app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "../serviceWorker";
import config from "../utils/config";
import { datadogRum } from "@datadog/browser-rum";

import "@datadog/browser-logs/bundle/datadog-logs";
import { v4 as uuidv4 } from "uuid";

const sessionId = uuidv4();
window.DD_LOGS.init({
  clientToken: "pubf6924c7bdd86ac5928f1118f9c414cd6",
  site: "us5.datadoghq.com",
  service: "capone-travel-portal",
  version: config.REACT_APP_VERSION,
  env: config.ENV,
  sampleRate: 70,
  trackInteractions: true,
  beforeSend: (log: any) => {
    log.browser_session_id = sessionId;
    log.path = window.location.pathname;
  },
});

datadogRum.init({
  applicationId: "b1dd9608-6937-41df-b2df-157828c040dc",
  clientToken: "pubf6924c7bdd86ac5928f1118f9c414cd6",
  site: "us5.datadoghq.com",
  service: "capone-travel-portal",
  env: config.ENV,
  sessionSampleRate: 70,
  sessionReplaySampleRate: config.SESSION_REPLAY_SAMPLE_RATE,
  version: config.REACT_APP_VERSION,
  trackUserInteractions: false,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask",
  allowedTracingUrls: [
    "https://travel.capitalone.com",
    "https://travel-qa.capitalone.com",
  ],
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
