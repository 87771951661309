import {
  deleteCookie,
  isCaponeTenant,
  isCorpTenant,
  PATH_CORP_FAQ,
  setCookie,
  USER_MEDIUM_KEY,
  USER_SOURCE_KEY,
  useUserContext,
  getCookie,
} from "@capone/common";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import {
  generateUserId,
  getShouldShowAnnualTravelCreditBanner,
  PostBookingOfferModal,
  useDeviceTypes,
} from "halifax";
import React, { useEffect, useMemo, useState } from "react";
import {
  Redirect,
  Route as RouteV5,
  RouteComponentProps,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  CallState,
  LandingScreen,
  LAUNCHED_APPLICATION,
  LaunchedApplicationProperties,
  PostBookingScreen,
  PRODUCT_FEED_LOADING,
  ProductFeedLoadingProperties,
  RewardsAccount,
  StatementCreditDetail,
  WalletDetailsResponse,
} from "redmond";
import { useExperimentIsVariant } from "@capone/experiments";
import dayjs from "dayjs";
import { CompatRoute } from "react-router-dom-v5-compat";
import { CarsApp } from "@capone/portal";

import { trackEvent } from "../../api/v1/trackEvent";
import {
  addTrackingProperties,
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CARS_MAINTENANCE,
  CARS_ONLY,
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  EXPERIENCES_EXPERIMENT,
  FLIGHTS_MAINTENANCE,
  FLIGHTS_ONLY,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_CROSS_SELL_V3,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT,
  HOTELS_MAINTENANCE,
  HOTELS_ONLY,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
  LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS,
  MAINTENANCE,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  NOT_SELF_FUNDED,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  POST_BOOKING_OFFER_EXPERIMENT,
  POST_BOOKING_OFFER_EXPERIMENT_REWARDS,
  POST_BOOKING_OFFER_MY_TRIPS,
  PREMIER_COLLECTION_EXPERIMENT,
  PREMIUM_STAYS_ONLY,
  RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
  RECENTLY_VIEWED_DESKTOP_P0_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
  RECENTLY_VIEWED_MOBILE_P0_VARIANTS,
  RECENTLY_VIEWED_V2_CARS,
  RECENTLY_VIEWED_V2_FLIGHTS,
  RESTRICT_FUNNELS,
  RESTRICT_FUNNELS_VARIANTS,
  SELF_FUNDED,
  STAYS_HOMEPAGE,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  TRIPS_MAINTENANCE,
  useExperiments,
  CORE_CARS,
  PACKAGES_MAINTENANCE,
} from "../../context/experiments";
import { useUtmParams } from "../../context/userSource";
import {
  DISPLAY_CROSS_SELL_AND_RV_PATHS,
  PATH_CARS,
  PATH_CARS_BOOK,
  PATH_CARS_SEARCH,
  PATH_CORP_ACCESS_DENIED,
  PATH_CORP_ACCESS_DENIED_NOT_BETA,
  PATH_CORP_ACKNOWLEDGEMENT,
  PATH_CUSTOMER_PROFILE,
  PATH_EXCHANGE,
  PATH_EXPERIENCES,
  PATH_FLIGHTS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOME,
  PATH_HOTELS,
  PATH_HOTELS_SEARCH,
  PATH_PACKAGES,
  PATH_PACKAGES_SEARCH,
  PATH_PREMIER_COLLECTION,
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_PREMIER_VACATION_RENTALS_SEARCH,
  PATH_SITE_TERMS_OF_USE,
  PATH_STAYS,
  PATH_STAYS_SEARCH,
  PATH_TERMS,
  PATH_TERMS_OF_SERVICE,
  PATH_TRAVEL_SALE,
  PATH_TRAVEL_WALLET,
  PATH_TRIPS,
  PATH_VACATION_RENTALS,
} from "../../utils/urlPaths";
import CarsModule from "../CarsModule";
import ExchangeModule from "../ExchangeModule";
import FlightsModule from "../FlightsModule";
import HotelsModule from "../HotelsModule";
import Maintenance from "../Maintenance/maintenance";
import { LandingPageType, MobileLandingPage } from "../MobileLandingPage";
import TermsModule from "../TermsModule";
import TravelWalletModule from "../TravelWalletModule";
import TripsModule from "../TripsModule";
import useStyles from "./styles";

import { listPaymentMethods } from "../../api/v1/payment-methods/listPaymentMethods";
import getPostBookingOffer from "../../api/v1/post-booking-ancillary-offer/getPostBookingOffer";
import { fetchTravelWalletDetails } from "../../api/v1/takeovers/fetchTravelWalletDetails";
import fetchUserFlightPreferences from "../../api/v1/user/fetchUserFlightPreferences";
import fetchUserHotelPreferences from "../../api/v1/user/fetchUserHotelPreferences";
import config from "../../utils/config";
import {
  AccessDenied,
  AccessDeniedNotInBeta,
  VXBOnboarding,
} from "../capone-corporate";
import CustomerProfileModule from "../CustomerProfileModule";
import ExperiencesModule from "../ExperiencesModule";
import { HomepageAnnualTravelCredit } from "../HomepageAnnualTravelCredit";
import { HomepageHotelCrossSell } from "../HomepageHotelCrossSell";
import { HomepageTravelSale } from "../HomepageTravelSale/component";
import PackagesModule from "../PackagesModule";
import PremierCollectionModule from "../PremierCollectionModule";
import VacationRentalsModule from "../VacationRentalsModule";
import { CorporateSwitchModal } from "./components/CorporateSwitchModal";
import { CrossSellProps, fetchCrossSellInfoV3 } from "./cross-sell-helpers";
import {
  fetchRecentlyViewedAndSearched,
  RecentlyViewedAndSearchedProps,
} from "./recently-viewed-and-searched-helpers";
import "./styles.scss";
import { HomePage } from "../HomePage";
import { RecentlyViewedAndSearched } from "../RecentlyViewedAndSearched";
import { ValuePropCarousel } from "../capone-corporate/ValuePropCarousel";
import AdminHomePageSection from "../capone-corporate/AdminHomePageSection/component";
import { LegacyCarsBooking } from "../Core";
import { useRedirectFromConsumer } from "../../hooks/useRedirectFromConsumer";

interface IBodyProps {
  language: string;
  className?: string;
  isFirstSession: boolean;
  launchEventSent: boolean;
  setLaunchEventSent: (val: boolean) => void;
  rewardsAccounts: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  fetchRewardsAccountsCallState: CallState;
  pathname: string;
  notAuthenticated?: boolean;
}

const Body = (props: IBodyProps) => {
  const {
    language,
    className,
    isFirstSession,
    launchEventSent,
    setLaunchEventSent,
    rewardsAccounts,
    fetchRewardsAccountsCallState,
    pathname,
    notAuthenticated,
  } = props;
  const { sessionInfo, isMultiAccount } = useUserContext(config.TENANT);
  const { matchesMobile } = useDeviceTypes();
  const expState = useExperiments();
  const classes = useStyles();
  const utmParams = useUtmParams();
  const history = useHistory();

  const [fetchedWalletDetails, setFetchedWalletDetails] = useState(false);
  const [fetchedPaymentsList, setFetchedPaymentsList] = useState({
    fetched: false,
    hasCardOnFile: false,
  });
  const [walletDetails, setWalletDetails] =
    useState<WalletDetailsResponse | null>(null);
  const [crossSellProps, setCrossSellProps] = useState<CrossSellProps | null>(
    null
  );
  const [crossSellLoaded, setCrossSellLoaded] = useState<boolean>(false);
  const [recentlyViewedAndSearchedProps, setRecentlyViewedAndSearchedProps] =
    useState<RecentlyViewedAndSearchedProps | null>(null);
  const [recentlyViewedLoaded, setRecentlyViewedLoaded] =
    useState<boolean>(false);
  const [, setUserHasNotSetHotelPreferences] = useState<boolean>(false);
  const [, setUserHasNotSetFlightPreferences] = useState<boolean>(false);
  const [showCrossSellV3UI, setShowCrossSellV3UI] = useState<boolean>(false);

  const { showCorporateSwitchModal } = useRedirectFromConsumer(
    rewardsAccounts,
    fetchRewardsAccountsCallState
  );

  const isInMaintenance =
    getExperimentVariant(expState.experiments, MAINTENANCE) === AVAILABLE;
  const flightsMaintenance =
    getExperimentVariant(expState.experiments, FLIGHTS_MAINTENANCE) ===
    AVAILABLE;
  const hotelsMaintenance =
    getExperimentVariant(expState.experiments, HOTELS_MAINTENANCE) ===
    AVAILABLE;
  const carsMaintenance =
    getExperimentVariant(expState.experiments, CARS_MAINTENANCE) === AVAILABLE;
  const tripsMaintenance =
    getExperimentVariant(expState.experiments, TRIPS_MAINTENANCE) === AVAILABLE;
  const packagesMaintenance =
    getExperimentVariant(expState.experiments, PACKAGES_MAINTENANCE) ===
    AVAILABLE;

  const isCorpOnboardingRevampEnabled =
    isCorpTenant(config.TENANT) &&
    getExperimentVariant(expState.experiments, "corp-onboarding-revamp") !==
      CONTROL;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isHotelCrossSellExperiment =
    getExperimentVariant(
      expState.experiments,
      HOTELS_HOMEPAGE_CROSS_SELL_EXPERIMENT
    ) === AVAILABLE;

  const isRecentlyViewedV1Experiment =
    getExperimentVariant(expState.experiments, RECENTLY_VIEWED_MASTER_V1) ===
    AVAILABLE;

  const isCorpMultiAccountRecentlyViewedExperiment = useExperimentIsVariant(
    "corp-multiaccount-recently-viewed",
    "available"
  );

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_DESKTOP_P0_EXPERIMENT,
    RECENTLY_VIEWED_DESKTOP_P0_VARIANTS
  );

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    RECENTLY_VIEWED_MOBILE_P0_EXPERIMENT,
    RECENTLY_VIEWED_MOBILE_P0_VARIANTS
  );
  const isRecentlyViewedFlightsV2 =
    getExperimentVariant(expState.experiments, RECENTLY_VIEWED_V2_FLIGHTS) ===
    AVAILABLE;

  const isRecentlyViewedCarsV2 =
    getExperimentVariant(expState.experiments, RECENTLY_VIEWED_V2_CARS) ===
    AVAILABLE;

  const isRecentlyViewedEnabled =
    isRecentlyViewedV1Experiment &&
    (!isMultiAccount || isCorpMultiAccountRecentlyViewedExperiment);

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const LCForNonPremiumCardholderVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_EXPERIMENT,
    LC_FOR_NON_PREMIUM_CARDHOLDERS_VARIANTS
  );

  const isLCForNonPremiumCardHoldersEnabled =
    LCForNonPremiumCardholderVariant !== CONTROL;

  const isPostBookingOffersAvailable = getExperimentVariant(
    expState.experiments,
    POST_BOOKING_OFFER_EXPERIMENT
  ).startsWith(AVAILABLE);

  const showPostBookingPayByRewardsMerch =
    getExperimentVariant(
      expState.experiments,
      POST_BOOKING_OFFER_EXPERIMENT_REWARDS
    ) === "available-with-merch";

  const isDisruptionAirCopyAvailable = getExperimentVariant(
    expState.experiments,
    POST_BOOKING_OFFER_EXPERIMENT
  ).startsWith(AVAILABLE);

  const isPostBookingMyTripsBannerAvailable = getExperimentVariant(
    expState.experiments,
    POST_BOOKING_OFFER_MY_TRIPS
  ).startsWith(AVAILABLE);

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  const isExperiencesExperiment =
    getExperimentVariant(expState.experiments, EXPERIENCES_EXPERIMENT) ===
    AVAILABLE;

  const isHotelCrossSellV3Enabled =
    getExperimentVariantCustomVariants(
      expState.experiments,
      HOTEL_CROSS_SELL_V3,
      HOTEL_CROSS_SELL_V3_VARIANTS
    ) !== CONTROL;

  const { shouldShowVentureBanner, shouldShowVentureXBanner } =
    getShouldShowAnnualTravelCreditBanner(
      rewardsAccounts,
      walletDetails?.creditBreakdown
    );

  const showAnnualTravelCredit =
    isAnnualTravelCreditsExperiment &&
    !!walletDetails &&
    (shouldShowVentureBanner || shouldShowVentureXBanner) &&
    !!sessionInfo?.userInfo.firstName;

  const restrictFunnelVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    RESTRICT_FUNNELS,
    RESTRICT_FUNNELS_VARIANTS
  );

  const staysHomepageEnabled =
    getExperimentVariant(expState.experiments, STAYS_HOMEPAGE) === AVAILABLE;

  const shouldRestrictFunnels = restrictFunnelVariant != CONTROL;

  const showCarsFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == CARS_ONLY;
  const showFlightsFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == FLIGHTS_ONLY;
  const showHotelsFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == HOTELS_ONLY;
  const showPremiumStaysFunnel =
    !shouldRestrictFunnels || restrictFunnelVariant == PREMIUM_STAYS_ONLY;

  const postBookingOfferModalConnectorPropsPropsShowOnPaths = useMemo(() => {
    const arr: {
      path: string;
      screen: PostBookingScreen;
    }[] = [
      { path: PATH_HOTELS, screen: "hotel_homepage" },
      { path: PATH_FLIGHTS, screen: "air_homepage" },
      { path: PATH_HOME, screen: "hotel_homepage" },
    ];

    if (!isPostBookingMyTripsBannerAvailable) {
      arr.push({
        path: PATH_TRIPS,
        screen: "my_trips_homepage",
      });
    }

    return arr;
  }, [isPostBookingMyTripsBannerAvailable]);

  const getLandingPageType = () => {
    switch (restrictFunnelVariant) {
      case CARS_ONLY:
        return LandingPageType.CARS;
      case FLIGHTS_ONLY:
        return LandingPageType.FLIGHTS;
      case HOTELS_ONLY:
        return LandingPageType.HOTELS;
      case PREMIUM_STAYS_ONLY:
        return LandingPageType.PREMIUM_STAYS;
      case CONTROL:
      default:
        return LandingPageType.HOTELS;
    }
  };

  const getRedirectPath = () => {
    switch (restrictFunnelVariant) {
      case CARS_ONLY:
        return PATH_CARS;
      case FLIGHTS_ONLY:
        return PATH_FLIGHTS;
      case HOTELS_ONLY:
        return `${PATH_HOTELS}${window.location.search}`;
      case PREMIUM_STAYS_ONLY:
        return PATH_PREMIER_COLLECTION;
      case CONTROL:
      default:
        return `${PATH_HOTELS}${window.location.search}`;
    }
  };

  const showCoreCars = getExperimentVariant(
    expState.experiments,
    CORE_CARS
  ).startsWith(AVAILABLE);

  useEffect(() => {
    setShowCrossSellV3UI(
      isHotelCrossSellV3Enabled && !!crossSellProps?.tysonsOffer
    );
  }, [crossSellProps]);

  useEffect(() => {
    if (!isFirstSession && !launchEventSent && sessionInfo?.csrfToken) {
      const fetchWalletDetails = async () => {
        const walletDetails = await fetchTravelWalletDetails().catch(
          () => null
        );
        setWalletDetails(walletDetails);
        setFetchedWalletDetails(true);
      };
      fetchWalletDetails();
    }
  }, [sessionInfo, isFirstSession, launchEventSent, setLaunchEventSent]);

  useEffect(() => {
    const doLaunch = async () => {
      if (
        !isFirstSession &&
        !launchEventSent &&
        sessionInfo?.csrfToken &&
        fetchedWalletDetails &&
        expState.trackingProperties
      ) {
        let account = null;
        let properties: LaunchedApplicationProperties = {
          landing_screen: getLandingScreen(),
          url: window.location.pathname,
          rewards_accounts: "",
          has_credits: !!walletDetails?.credit?.amount?.amount,
          credit_balance: walletDetails?.credit?.amount?.amount || 0,
          vx_statement_credit_balance:
            walletDetails?.creditBreakdown
              ?.filter((b) => b.CreditDetail === "Statement")
              .reduce(
                (prev, curr) =>
                  prev + (curr as StatementCreditDetail).usableAmount.amount,
                0
              ) || 0,
        };

        if (utmParams) {
          const { userSource, userMedium } = utmParams;
          properties[USER_SOURCE_KEY] =
            userSource ?? sessionStorage.getItem(USER_SOURCE_KEY) ?? "";
          properties[USER_MEDIUM_KEY] =
            userMedium ?? sessionStorage.getItem(USER_MEDIUM_KEY) ?? "";
          window?.DD_LOGS?.logger?.info(
            `Launched application event - utm_source ${properties[USER_SOURCE_KEY]} - utm_medium ${properties[USER_MEDIUM_KEY]}`
          );
        }

        if (rewardsAccounts.length > 0) {
          properties.rewards_accounts = rewardsAccounts
            .map((r) => r.productDisplayName)
            .join(",");
          const earnMultiplier = (account: RewardsAccount) =>
            account.earn.hotelsMultiplier ?? -1;
          account = rewardsAccounts.sort(
            (prev, current) => earnMultiplier(current) - earnMultiplier(prev)
          )[0];
        }
        properties = addTrackingProperties(
          expState.trackingProperties,
          properties
        );

        // Delete non-root session cookies to prevent inconsistent behavior on different paths
        deleteCookie("browser_session_id", "/hotels/availability");
        deleteCookie("browser_session_id", "/hotels/shop");
        deleteCookie("browser_session_id", "/hotels/book");
        deleteCookie("browser_session_id", "/flights/shop");
        deleteCookie("browser_session_id", "/cars/availability");

        const sessionId = getCookie("browser_session_id");
        if (!sessionId) {
          setCookie("browser_session_id", generateUserId());
        }

        let hasPaymentMethods = fetchedPaymentsList.hasCardOnFile;
        if (!fetchedPaymentsList.fetched) {
          // Set it before the await call, otherwise this codepath will still get triggered multiple times
          setFetchedPaymentsList({
            fetched: true,
            hasCardOnFile: fetchedPaymentsList.hasCardOnFile,
          });

          try {
            const payments = await listPaymentMethods();
            hasPaymentMethods = payments.length > 0;
            setFetchedPaymentsList({
              fetched: true,
              hasCardOnFile: hasPaymentMethods,
            });
          } catch (e) {
            console.error(e);
          }
        }

        if (fetchedPaymentsList.fetched) {
          trackEvent({
            eventName: LAUNCHED_APPLICATION,
            properties: {
              ...properties,
              account_use_type: account?.accountUseType,
              account_allow_rewards_redemption: account?.allowRewardsRedemption,
              card_on_file: hasPaymentMethods,
            },
          });
        }

        setLaunchEventSent(true);
      }
    };

    doLaunch();
  }, [
    sessionInfo,
    expState,
    isFirstSession,
    launchEventSent,
    utmParams,
    setLaunchEventSent,
    rewardsAccounts,
    fetchedWalletDetails,
    fetchedPaymentsList.fetched,
  ]);

  useEffect(() => {
    const getUserPrefs = async () => {
      const userHotelPreferences = await fetchUserHotelPreferences();

      const hasSetHotelPreferences =
        !!userHotelPreferences?.amenities.length ||
        !!userHotelPreferences?.starRatings.length ||
        !!userHotelPreferences?.freeCancellationOnly;

      setUserHasNotSetHotelPreferences(!hasSetHotelPreferences);

      const userFlightPreferences = await fetchUserFlightPreferences();

      const hasSetFlightPreferences =
        !!userFlightPreferences.airlines.length ||
        !!userFlightPreferences.homeAirport ||
        userFlightPreferences.nonStopOnly ||
        Object.values(userFlightPreferences.fareClasses).some(
          (val) => val == true
        );

      setUserHasNotSetFlightPreferences(!hasSetFlightPreferences);
    };

    if (matchesMobile && isCustomerProfileExperiment) {
      getUserPrefs();
    }
  }, [matchesMobile, isCustomerProfileExperiment, pathname]);

  const displayCrossSellRV = React.useMemo(
    () => DISPLAY_CROSS_SELL_AND_RV_PATHS.includes(pathname),
    [pathname]
  );

  useEffect(() => {
    if (
      displayCrossSellRV &&
      rewardsAccounts.length > 0 &&
      isHotelCrossSellExperiment
    ) {
      const earnMultiplier = (account: RewardsAccount) =>
        account.earn.hotelsMultiplier ?? -1;
      const account = rewardsAccounts.sort(
        (prev, current) => earnMultiplier(current) - earnMultiplier(prev)
      )[0];
      const fetchCrossSell = async () => {
        const props: CrossSellProps | false = await fetchCrossSellInfoV3(
          account,
          isHotelCrossSellV3Enabled
        );
        if (props) {
          setCrossSellProps(props);
          setCrossSellLoaded(true);
        }
      };
      const properties: ProductFeedLoadingProperties = {
        feed_placement: "homepage",
        feed_type: "cross_sell_hotels",
      };
      trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
      fetchCrossSell();
    }
  }, [rewardsAccounts, isHotelCrossSellExperiment, displayCrossSellRV]);

  useEffect(() => {
    if (
      displayCrossSellRV &&
      rewardsAccounts.length > 0 &&
      isRecentlyViewedEnabled &&
      ((!matchesMobile && recentlyViewedDesktopP0Variant !== CONTROL) ||
        (matchesMobile && recentlyViewedMobileP0Variant !== CONTROL))
    ) {
      const earnMultiplier = (account: RewardsAccount) =>
        account.earn.hotelsMultiplier ?? -1;
      const account = rewardsAccounts.sort(
        (prev, current) => earnMultiplier(current) - earnMultiplier(prev)
      )[0];
      const fetchRecentViewsAndSearches = async () => {
        const props: RecentlyViewedAndSearchedProps | false =
          await fetchRecentlyViewedAndSearched(
            account,
            recentlyViewedDesktopP0Variant,
            isRecentlyViewedFlightsV2,
            isRecentlyViewedCarsV2,
            isPackagesExperiment
          );
        if (props) {
          setRecentlyViewedAndSearchedProps(props);
          setRecentlyViewedLoaded(true);
        } else {
          setRecentlyViewedLoaded(true);
        }
      };
      const properties: ProductFeedLoadingProperties = {
        feed_placement: "homepage",
        feed_type: "recently_viewed",
      };
      trackEvent({ eventName: PRODUCT_FEED_LOADING, properties });
      fetchRecentViewsAndSearches();
    }
  }, [
    rewardsAccounts,
    isRecentlyViewedEnabled,
    recentlyViewedDesktopP0Variant,
    recentlyViewedMobileP0Variant,
    matchesMobile,
    displayCrossSellRV,
    isRecentlyViewedFlightsV2,
    isRecentlyViewedCarsV2,
  ]);

  const isFetchingRewards = useMemo(
    () =>
      fetchRewardsAccountsCallState === CallState.NotCalled ||
      fetchRewardsAccountsCallState === CallState.InProcess,
    [fetchRewardsAccountsCallState]
  );

  const isHotelsOrStaysPath = (path: string) =>
    path == PATH_HOTELS || path == PATH_STAYS;

  const corpHomePage = (
    <>
      <ValuePropCarousel />
      <AdminHomePageSection sessionInfo={sessionInfo} />
    </>
  );

  return (
    <main className={clsx("main-body", classes.main, className)}>
      {isInMaintenance ? (
        <Maintenance />
      ) : (
        <Grid className={clsx("main-body-content", classes.content)}>
          <Switch>
            <RouteV5
              exact
              path={PATH_FLIGHTS_SEARCH}
              render={(browserRouterProps: RouteComponentProps) =>
                flightsMaintenance ? (
                  <Maintenance />
                ) : matchesMobile ? (
                  <FlightsModule
                    crossSellLoaded={false}
                    language={language}
                    experiments={expState}
                    recentlyViewedLoaded={false}
                    annualTravelCreditLoaded={false}
                    {...browserRouterProps}
                  />
                ) : (
                  <Redirect to={PATH_FLIGHTS} />
                )
              }
            />
            <RouteV5
              path={PATH_FLIGHTS}
              render={(browserRouterProps: RouteComponentProps) =>
                flightsMaintenance ? (
                  <Maintenance />
                ) : !showFlightsFunnel ? (
                  <Redirect to={getRedirectPath()} />
                ) : matchesMobile &&
                  browserRouterProps.history.location.pathname ===
                    PATH_FLIGHTS &&
                  mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                  <>
                    <MobileLandingPage
                      type={LandingPageType.FLIGHTS}
                      rewardsAccounts={rewardsAccounts}
                      recentlyViewedAndSearchedProps={
                        recentlyViewedAndSearchedProps
                      }
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                      recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                    />
                    {isHotelCrossSellExperiment && crossSellProps ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                ) : (
                  <>
                    <FlightsModule
                      language={language}
                      experiments={expState}
                      crossSellLoaded={crossSellLoaded}
                      recentlyViewedLoaded={
                        recentlyViewedLoaded || isCorpOnboardingRevampEnabled
                      }
                      annualTravelCreditLoaded={showAnnualTravelCredit}
                      {...browserRouterProps}
                    />
                    {showAnnualTravelCredit &&
                      browserRouterProps.history.location.pathname ===
                        PATH_FLIGHTS && (
                        <HomepageAnnualTravelCredit
                          firstName={sessionInfo.userInfo.firstName}
                          rewardsAccounts={rewardsAccounts}
                          walletDetails={walletDetails}
                          isAnnualTravelCreditsExperiment={
                            isAnnualTravelCreditsExperiment
                          }
                        />
                      )}
                    {isTravelSaleEnabled &&
                      browserRouterProps.history.location.pathname ===
                        PATH_FLIGHTS && (
                        <HomepageTravelSale
                          active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
                          offers={walletDetails?.offers || []}
                        />
                      )}
                    {isHotelCrossSellExperiment &&
                    showCrossSellV3UI &&
                    !!crossSellProps &&
                    browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {recentlyViewedDesktopP0Variant !== CONTROL &&
                    rewardsAccounts &&
                    browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS ? (
                      <RecentlyViewedAndSearched
                        {...recentlyViewedAndSearchedProps}
                        loading={!recentlyViewedLoaded}
                        showSkeleton={isCorpOnboardingRevampEnabled}
                      />
                    ) : null}
                    {browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS && corpHomePage}
                    {isHotelCrossSellExperiment &&
                    !showCrossSellV3UI &&
                    !!crossSellProps &&
                    browserRouterProps.history.location.pathname ===
                      PATH_FLIGHTS ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                )
              }
            />
            <RouteV5
              exact
              path={[PATH_HOTELS_SEARCH, PATH_STAYS_SEARCH]}
              render={(browserRouterProps: RouteComponentProps) =>
                hotelsMaintenance ? (
                  <Maintenance />
                ) : matchesMobile ? (
                  <HotelsModule
                    language={language}
                    {...browserRouterProps}
                    experiments={expState}
                    crossSellLoaded={false}
                    recentlyViewedLoaded={false}
                    annualTravelCreditLoaded={false}
                  />
                ) : (
                  <Redirect to={PATH_HOTELS} />
                )
              }
            />
            <RouteV5
              path={[PATH_HOTELS, PATH_STAYS]}
              render={(browserRouterProps: RouteComponentProps) =>
                hotelsMaintenance ? (
                  <Maintenance />
                ) : !showHotelsFunnel ? (
                  <Redirect to={getRedirectPath()} />
                ) : matchesMobile &&
                  isHotelsOrStaysPath(
                    browserRouterProps.history.location.pathname
                  ) &&
                  mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                  <>
                    <MobileLandingPage
                      type={
                        isHotelsOrStaysPath(
                          browserRouterProps.history.location.pathname
                        )
                          ? staysHomepageEnabled
                            ? LandingPageType.STAYS
                            : LandingPageType.HOTELS
                          : LandingPageType.HOTELS
                      }
                      rewardsAccounts={rewardsAccounts}
                      recentlyViewedAndSearchedProps={
                        recentlyViewedAndSearchedProps
                      }
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                      recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                    />
                    {isHotelCrossSellExperiment && crossSellProps ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                ) : (
                  <>
                    <HotelsModule
                      language={language}
                      crossSellLoaded={crossSellLoaded}
                      recentlyViewedLoaded={
                        recentlyViewedLoaded || isCorpOnboardingRevampEnabled
                      }
                      annualTravelCreditLoaded={showAnnualTravelCredit}
                      {...browserRouterProps}
                      experiments={expState}
                    />
                    {showAnnualTravelCredit &&
                      isHotelsOrStaysPath(
                        browserRouterProps.history.location.pathname
                      ) && (
                        <HomepageAnnualTravelCredit
                          firstName={sessionInfo.userInfo.firstName}
                          rewardsAccounts={rewardsAccounts}
                          walletDetails={walletDetails}
                          isAnnualTravelCreditsExperiment={
                            isAnnualTravelCreditsExperiment
                          }
                        />
                      )}
                    {isTravelSaleEnabled &&
                      isHotelsOrStaysPath(
                        browserRouterProps.history.location.pathname
                      ) && (
                        <HomepageTravelSale
                          active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
                          offers={walletDetails?.offers || []}
                        />
                      )}
                    {isHotelCrossSellExperiment &&
                    showCrossSellV3UI &&
                    crossSellProps &&
                    isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                    {recentlyViewedDesktopP0Variant !== CONTROL &&
                    rewardsAccounts &&
                    isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) ? (
                      <RecentlyViewedAndSearched
                        {...recentlyViewedAndSearchedProps}
                        loading={!recentlyViewedLoaded}
                        showSkeleton={isCorpOnboardingRevampEnabled}
                      />
                    ) : null}
                    {isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) && corpHomePage}
                    {isHotelCrossSellExperiment &&
                    !showCrossSellV3UI &&
                    crossSellProps &&
                    isHotelsOrStaysPath(
                      browserRouterProps.history.location.pathname
                    ) ? (
                      <HomepageHotelCrossSell {...crossSellProps} />
                    ) : null}
                  </>
                )
              }
            />
            {showCoreCars ? (
              <RouteV5
                path={PATH_CARS_BOOK}
                render={(browserRouterProps: RouteComponentProps) =>
                  carsMaintenance ? (
                    <Maintenance />
                  ) : (
                    <LegacyCarsBooking
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                      crossSellLoaded={false}
                      recentlyViewedLoaded={false}
                      annualTravelCreditLoaded={false}
                    />
                  )
                }
              />
            ) : null}
            {showCoreCars ? (
              <RouteV5
                exact
                path={PATH_CARS}
                render={() => (
                  <HomePage
                    type={LandingPageType.CARS}
                    rewardsAccounts={rewardsAccounts}
                    fetchRewardsAccountsCallState={
                      fetchRewardsAccountsCallState
                    }
                    walletDetails={walletDetails}
                    crossSellProps={crossSellProps}
                    recentlyViewedAndSearchedProps={
                      recentlyViewedAndSearchedProps
                    }
                    recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                  />
                )}
              />
            ) : (
              <RouteV5
                path={PATH_CARS}
                render={(browserRouterProps: RouteComponentProps) =>
                  carsMaintenance ? (
                    <Maintenance />
                  ) : !showCarsFunnel ? (
                    <Redirect to={getRedirectPath()} />
                  ) : matchesMobile &&
                    browserRouterProps.history.location.pathname ===
                      PATH_CARS &&
                    mobileHomeScreenVariant !==
                      MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                    <>
                      <MobileLandingPage
                        type={LandingPageType.CARS}
                        rewardsAccounts={rewardsAccounts}
                        recentlyViewedAndSearchedProps={
                          recentlyViewedAndSearchedProps
                        }
                        fetchRewardsAccountsCallState={
                          fetchRewardsAccountsCallState
                        }
                        recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                      />
                      {isHotelCrossSellExperiment && crossSellProps ? (
                        <HomepageHotelCrossSell {...crossSellProps} />
                      ) : null}
                    </>
                  ) : (
                    <>
                      <CarsModule
                        language={language}
                        {...browserRouterProps}
                        experiments={expState}
                        crossSellLoaded={crossSellLoaded}
                        recentlyViewedLoaded={
                          recentlyViewedLoaded || isCorpOnboardingRevampEnabled
                        }
                        annualTravelCreditLoaded={showAnnualTravelCredit}
                      />
                      {showAnnualTravelCredit &&
                        browserRouterProps.history.location.pathname ===
                          PATH_CARS && (
                          <HomepageAnnualTravelCredit
                            firstName={sessionInfo.userInfo.firstName}
                            rewardsAccounts={rewardsAccounts}
                            walletDetails={walletDetails}
                            isAnnualTravelCreditsExperiment={
                              isAnnualTravelCreditsExperiment
                            }
                          />
                        )}
                      {isTravelSaleEnabled &&
                        browserRouterProps.history.location.pathname ===
                          PATH_CARS && (
                          <HomepageTravelSale
                            active={travelSaleVariant === TRAVEL_SALE_ACTIVE}
                            offers={walletDetails?.offers || []}
                          />
                        )}
                      {isHotelCrossSellExperiment &&
                      showCrossSellV3UI &&
                      crossSellProps &&
                      browserRouterProps.history.location.pathname ===
                        PATH_CARS ? (
                        <HomepageHotelCrossSell {...crossSellProps} />
                      ) : null}
                      {recentlyViewedDesktopP0Variant !== CONTROL &&
                      rewardsAccounts &&
                      browserRouterProps.history.location.pathname ===
                        PATH_CARS ? (
                        <RecentlyViewedAndSearched
                          {...recentlyViewedAndSearchedProps}
                          loading={!recentlyViewedLoaded}
                          showSkeleton={isCorpOnboardingRevampEnabled}
                        />
                      ) : null}
                      {browserRouterProps.history.location.pathname ===
                        PATH_CARS && corpHomePage}
                      {isHotelCrossSellExperiment &&
                      !showCrossSellV3UI &&
                      crossSellProps &&
                      browserRouterProps.history.location.pathname ===
                        PATH_CARS ? (
                        <HomepageHotelCrossSell {...crossSellProps} />
                      ) : null}
                    </>
                  )
                }
              />
            )}
            {showCoreCars ? (
              <CompatRoute path={PATH_CARS}>
                <CarsApp />
              </CompatRoute>
            ) : (
              <RouteV5
                exact
                path={PATH_CARS_SEARCH}
                render={(browserRouterProps: RouteComponentProps) =>
                  carsMaintenance ? (
                    <Maintenance />
                  ) : matchesMobile ? (
                    <CarsModule
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                      crossSellLoaded={false}
                      recentlyViewedLoaded={false}
                      annualTravelCreditLoaded={false}
                    />
                  ) : (
                    <Redirect to={PATH_CARS} />
                  )
                }
              />
            )}

            <RouteV5
              exact
              path={[
                PATH_PREMIER_COLLECTION_SEARCH,
                PATH_PREMIER_VACATION_RENTALS_SEARCH,
              ]}
              render={(browserRouterProps: RouteComponentProps) => {
                if (
                  showPremiumStaysFunnel &&
                  (isPremierCollectionEnabled ||
                    isLCForNonPremiumCardHoldersEnabled) &&
                  (matchesMobile // Adding a check on experiment be safe of users navigating to /premier-collection. Users who are on control should not see PC as PC search is not set up in control variant (this should be dated by experiment in prod but adding a check here for staging accts & to be safe )
                    ? mobileHomeScreenVariant !== CONTROL ||
                      isCorpTenant(config.TENANT)
                    : true)
                ) {
                  return (
                    <PremierCollectionModule
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                    />
                  );
                }
                return isFetchingRewards ? (
                  <div>Loading...</div> // TODO: Add loading component
                ) : (
                  <Redirect to={getRedirectPath()} />
                );
              }}
            />
            <RouteV5
              path={PATH_PREMIER_COLLECTION}
              render={(browserRouterProps: RouteComponentProps) => {
                if (
                  showPremiumStaysFunnel &&
                  (isPremierCollectionEnabled ||
                    isLCForNonPremiumCardHoldersEnabled) &&
                  (matchesMobile // Adding a check on experiment be safe of users navigating to /premier-collection. Users who are on control should not see PC as PC search is not set up in control variant (this should be dated by experiment in prod but adding a check here for staging accts & to be safe )
                    ? mobileHomeScreenVariant !== CONTROL ||
                      isCorpTenant(config.TENANT)
                    : true)
                ) {
                  if (
                    matchesMobile &&
                    browserRouterProps.history.location.pathname ===
                      PATH_PREMIER_COLLECTION &&
                    mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3
                  ) {
                    return (
                      <MobileLandingPage
                        type={LandingPageType.PREMIUM_STAYS}
                        rewardsAccounts={rewardsAccounts}
                        recentlyViewedAndSearchedProps={
                          recentlyViewedAndSearchedProps
                        }
                        fetchRewardsAccountsCallState={
                          fetchRewardsAccountsCallState
                        }
                        recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                      />
                    );
                  }
                  return (
                    <>
                      <PremierCollectionModule
                        language={language}
                        {...browserRouterProps}
                        experiments={expState}
                      />
                      {showAnnualTravelCredit &&
                        browserRouterProps.history.location.pathname ===
                          PATH_PREMIER_COLLECTION && (
                          <HomepageAnnualTravelCredit
                            firstName={sessionInfo.userInfo.firstName}
                            rewardsAccounts={rewardsAccounts}
                            walletDetails={walletDetails}
                            isAnnualTravelCreditsExperiment={
                              isAnnualTravelCreditsExperiment
                            }
                            showBanner={false}
                          />
                        )}
                    </>
                  );
                }
                return isFetchingRewards ? (
                  <div>Loading...</div> // TODO: Add loading component
                ) : (
                  ([CallState.Success, CallState.Failed].includes(
                    expState.callState
                  ) ||
                    expState.experiments.length > 0) && (
                    <Redirect to={getRedirectPath()} />
                  )
                );
              }}
            />
            <RouteV5
              path={PATH_VACATION_RENTALS}
              render={(browserRouterProps: RouteComponentProps) => (
                <VacationRentalsModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />
            <RouteV5
              path={PATH_TRIPS}
              render={(browserRouterProps: RouteComponentProps) =>
                tripsMaintenance ? (
                  <Maintenance />
                ) : (
                  <TripsModule
                    experiments={expState}
                    language={language}
                    {...browserRouterProps}
                  />
                )
              }
            />
            <RouteV5
              path={[
                PATH_TERMS,
                PATH_TERMS_OF_SERVICE,
                PATH_SITE_TERMS_OF_USE,
                PATH_CORP_FAQ,
              ]}
              render={(browserRouterProps: RouteComponentProps) => (
                <TermsModule
                  language={language}
                  experiments={expState}
                  authenticated={!notAuthenticated}
                  {...browserRouterProps}
                />
              )}
            />
            <RouteV5
              path={PATH_EXCHANGE}
              render={(browserRouterProps: RouteComponentProps) => (
                <ExchangeModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />
            <RouteV5
              exact
              path={PATH_TRAVEL_SALE}
              render={(browserRouterProps: RouteComponentProps) => {
                if (isTravelSaleEnabled) {
                  return (
                    <TravelWalletModule
                      language={language}
                      {...browserRouterProps}
                      experiments={expState}
                    />
                  );
                }
                return isFetchingRewards ? (
                  <div>Loading...</div> // TODO: Add loading component
                ) : (
                  <Redirect to={PATH_HOTELS} />
                );
              }}
            />
            <RouteV5
              path={PATH_TRAVEL_WALLET}
              render={(browserRouterProps: RouteComponentProps) => (
                <TravelWalletModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />
            <RouteV5
              path={PATH_CUSTOMER_PROFILE}
              render={(browserRouterProps: RouteComponentProps) => (
                <CustomerProfileModule
                  experiments={expState}
                  language={language}
                  {...browserRouterProps}
                />
              )}
            />
            <RouteV5
              exact
              path={PATH_PACKAGES_SEARCH}
              render={(browserRouterProps: RouteComponentProps) =>
                packagesMaintenance ? (
                  <Maintenance />
                ) : isPackagesExperiment ? (
                  matchesMobile ? (
                    <PackagesModule
                      experiments={expState}
                      language={language}
                      recentlyViewedLoaded={false}
                      {...browserRouterProps}
                    />
                  ) : (
                    <Redirect to={PATH_PACKAGES} />
                  )
                ) : (
                  <Redirect to={getRedirectPath()} />
                )
              }
            />
            <RouteV5
              path={PATH_PACKAGES}
              render={(browserRouterProps: RouteComponentProps) =>
                packagesMaintenance ? (
                  <Maintenance />
                ) : isPackagesExperiment ? (
                  matchesMobile &&
                  browserRouterProps.history.location.pathname ===
                    PATH_PACKAGES ? (
                    <MobileLandingPage
                      type={LandingPageType.PACKAGES}
                      rewardsAccounts={rewardsAccounts}
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                      recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                    />
                  ) : (
                    <>
                      <PackagesModule
                        experiments={expState}
                        language={language}
                        recentlyViewedLoaded={
                          recentlyViewedLoaded || isCorpOnboardingRevampEnabled
                        }
                        {...browserRouterProps}
                      />
                      {recentlyViewedDesktopP0Variant !== CONTROL &&
                      rewardsAccounts &&
                      browserRouterProps.history.location.pathname ===
                        PATH_PACKAGES ? (
                        <RecentlyViewedAndSearched
                          {...recentlyViewedAndSearchedProps}
                          loading={!recentlyViewedLoaded}
                          showSkeleton={isCorpOnboardingRevampEnabled}
                        />
                      ) : null}
                      {browserRouterProps.history.location.pathname ===
                        PATH_PACKAGES && corpHomePage}
                    </>
                  )
                ) : (
                  ([CallState.Success, CallState.Failed].includes(
                    expState.callState
                  ) ||
                    expState.experiments.length > 0) && (
                    <Redirect to={getRedirectPath()} />
                  )
                )
              }
            />
            <RouteV5
              path={PATH_EXPERIENCES}
              render={(browserRouterProps: RouteComponentProps) =>
                isExperiencesExperiment ? (
                  matchesMobile &&
                  browserRouterProps.history.location.pathname ===
                    PATH_EXPERIENCES ? (
                    <MobileLandingPage
                      type={LandingPageType.EXPERIENCES}
                      rewardsAccounts={rewardsAccounts}
                      fetchRewardsAccountsCallState={
                        fetchRewardsAccountsCallState
                      }
                      recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                    />
                  ) : (
                    <>
                      <ExperiencesModule
                        experiments={expState}
                        language={language}
                        {...browserRouterProps}
                      />
                      {browserRouterProps.history.location.pathname ===
                        PATH_EXPERIENCES &&
                      recentlyViewedDesktopP0Variant !== CONTROL &&
                      rewardsAccounts ? (
                        <RecentlyViewedAndSearched
                          {...recentlyViewedAndSearchedProps}
                          loading={!recentlyViewedLoaded}
                          showSkeleton={isCorpOnboardingRevampEnabled}
                        />
                      ) : null}
                      {corpHomePage}
                      {isHotelCrossSellExperiment &&
                      browserRouterProps.history.location.pathname ===
                        PATH_EXPERIENCES &&
                      !showCrossSellV3UI &&
                      !!crossSellProps ? (
                        <HomepageHotelCrossSell {...crossSellProps} />
                      ) : null}
                    </>
                  )
                ) : (
                  ([CallState.Success, CallState.Failed].includes(
                    expState.callState
                  ) ||
                    expState.experiments.length > 0) && (
                    <Redirect to={getRedirectPath()} />
                  )
                )
              }
            />
            <RouteV5
              path={PATH_CORP_ACKNOWLEDGEMENT}
              render={() => <VXBOnboarding />}
            />
            <RouteV5
              path={PATH_CORP_ACCESS_DENIED}
              render={() => <AccessDenied />}
            />
            <RouteV5
              path={PATH_CORP_ACCESS_DENIED_NOT_BETA}
              render={() => <AccessDeniedNotInBeta />}
            />
            <RouteV5 path="*">
              {/* TODO: once we have started working on the new mobile search workflow changes, this component will likely be relocated
            so that RewardsBanner can be added to the mobile home page; see https://hopper-jira.atlassian.net/browse/BP-725 */}
              {matchesMobile &&
              mobileHomeScreenVariant !== MOBILE_HOMESCREEN_REDESIGN_V2 ? (
                <>
                  <MobileLandingPage
                    type={getLandingPageType()}
                    rewardsAccounts={rewardsAccounts}
                    recentlyViewedAndSearchedProps={
                      recentlyViewedAndSearchedProps
                    }
                    recentlyViewedAndSearchedLoaded={recentlyViewedLoaded}
                    fetchRewardsAccountsCallState={
                      fetchRewardsAccountsCallState
                    }
                  />
                  {isHotelCrossSellExperiment && crossSellProps ? (
                    <HomepageHotelCrossSell {...crossSellProps} />
                  ) : null}
                </>
              ) : (
                <Redirect to={getRedirectPath()} />
              )}
            </RouteV5>
          </Switch>
          <PostBookingOfferModal
            history={history}
            showOnPaths={postBookingOfferModalConnectorPropsPropsShowOnPaths}
            getPostBookingOffer={() =>
              getPostBookingOffer({
                referenceDateTime: dayjs().format(),
              })
            }
            showRewardsMerch={showPostBookingPayByRewardsMerch}
            newAirCopy={isDisruptionAirCopyAvailable}
            isPostBookingOffersAvailable={isPostBookingOffersAvailable}
            trackEvent={trackEvent}
          />
          {isCaponeTenant(config.TENANT) && (
            <CorporateSwitchModal open={showCorporateSwitchModal} />
          )}
        </Grid>
      )}
    </main>
  );
};

const getLandingScreen = (): LandingScreen => {
  const path = window.location.pathname;
  if (path.includes("flights")) {
    return path.includes("search") ? "flight_search" : "flight_shop";
  }
  if (path.includes("cars")) {
    return path.includes("search") ? "cars_search" : "cars_list";
  }
  if (path.includes("hotels")) {
    return path.includes("search") ? "hotel_search" : "hotel_list";
  }
  if (path.includes("trips")) {
    return "my_trips";
  }
  if (path.includes("exchange")) {
    return "exchange";
  }
  if (path.includes("travel-wallet")) {
    return "travel_wallet";
  }
  return "hotel_search";
};

export default Body;
