import React from "react";
import { useDeviceTypes } from "halifax";

import { CorpBusinessWithoutRewardsAccount, RewardsAccount } from "redmond";
import MobileHeader from "./components/MobileHeader";
import DesktopHeader from "./components/DesktopHeader";

interface IHeaderProps {
  language: string;
  locationPath: string;
  rewardsAccounts: RewardsAccount[];
  selectedRewardsAccountId: string | null;
  setSelectedRewardsAccountId: (id: string | null) => void;
  notAuthenticated?: boolean;
  className?: string;
  showCorpMigrationNotice?: boolean;
  corpBusinessAccounts: CorpBusinessWithoutRewardsAccount[];
}

const Header = (props: IHeaderProps) => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? (
    <MobileHeader {...props} />
  ) : (
    <DesktopHeader {...props} />
  );
};

export default Header;
