import {
  CORPORATE_ADMIN_URL,
  isCaponeTenant,
  ONBOARDING_REFERRER_SOURCE,
} from "@capone/common";
import { useExperimentIsVariant } from "@capone/experiments";
import { Box, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  CorporateButton,
  CorporateTypography,
  Icon,
  IconName,
  PagedCardCarousel,
  useDeviceTypes,
} from "halifax";
import React, { useEffect, useMemo, useState } from "react";
import { InView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import {
  BusinessModalNames,
  CorpSessionInfo,
  defaultCorpColors,
  SessionInfo,
} from "redmond";
import { config } from "../../../api/config";
import { trackEvent } from "../../../api/v1/trackEvent";
import { getHasSeenModalMap } from "../../../capone-corporate/selectors";
import businessIllustrationbg from "./admin-home-page.png";

interface AdminHomePageSectionProps {
  sessionInfo: SessionInfo | CorpSessionInfo;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: "92px",
    paddingBottom: theme.spacing(8),
    backgroundColor: defaultCorpColors["oat-warm-gray"],
    [theme.breakpoints.down("sm")]: {
      padding: "24px 10px",
    },
  },
  container: {
    margin: "0 auto",
  },
  mainTitle: {
    marginBottom: "10px",
    paddingLeft: "70px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      marginBottom: "20px",
    },
  },
  mainContent: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: "0px 20px",
      gap: "64px",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      background: "none",
      gap: "8px",
    },
  },
  panelContentContainer: {
    flex: 3,
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  panelContentWrapper: {
    display: "flex",
    gap: "64px",
    minHeight: 443,
    flexDirection: "row",
    paddingLeft: 0,
    paddingRight: "32px",
    background: "#D9EBED",
    borderTopRightRadius: "36px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      gap: "10px",
      minHeight: "unset",
      flexDirection: "column",
      padding: "16px",
      borderRadius: "0px",
    },
  },
  illustration: {
    width: "auto",
    maxHeight: "440px",
    marginTop: "32px",
    marginBottom: "-32px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
      margin: "0 20px",
    },
  },
  panelContent: {
    width: "40%",
    maxWidth: "270px",
    alignSelf: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      maxWidth: "unset",
      gap: "10px",
    },
  },
  featureIcon: {
    color: "#fff",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
  getStartedText: {
    "&.MuiTypography-root": {
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        marginBottom: "0px",
      },
    },
  },
  featuresWrapper: {
    flex: 1,
    display: "block",
    paddingLeft: "30px",
    paddingRight: "30px",
    paddingBottom: "30px",
    paddingTop: "50px",
    marginLeft: "24px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  featureContainer: {
    display: "flex",
    marginBottom: "40px",
    alignItems: "center",
  },
  featureLabelContainer: { maxWidth: "320px", marginLeft: "16px" },
  carouselContainer: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  carouselPage: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
    textAlign: "center",
  },
}));

const features = [
  {
    key: "cost-control",
    icon: <Icon name={IconName.CorpCash} />,
    label: (
      <>
        <strong>Control costs</strong> by setting policy limits for your
        employees on things like flight prices, fare class and more.
      </>
    ),
  },
  {
    key: "save-time",
    icon: <Icon name={IconName.CorpClock} />,
    label: (
      <>
        <strong>Save time</strong> and reduce friction for your travelers by
        setting auto approvals for out-of-policy bookings.
      </>
    ),
  },
  {
    key: "manage-traveler-profiles",
    icon: <Icon name={IconName.CorpSuitcase} />,
    label: (
      <>
        <strong>Manage traveler profiles</strong> for other employees to
        streamline the trip planning process.
      </>
    ),
  },
  {
    key: "gain-insights",
    icon: <Icon name={IconName.CorpData} />,
    label: (
      <>
        <strong>Gain real-time insights</strong> into your business travel spend
        with our intuitive reporting dashboard.
      </>
    ),
  },
];

const FeatureCarousel = ({ itemsPerPage }: { itemsPerPage: number }) => {
  const classes = useStyles();
  const trackedPages = useMemo(() => new Set<string>(), []);

  return (
    <Box className={classes.carouselContainer}>
      <PagedCardCarousel
        itemsPerPage={itemsPerPage}
        cardContents={features.map((feature) => (
          <Box key={feature.key} className={classes.carouselPage}>
            {feature.icon}
            <CorporateTypography variant="subtitle2">
              {feature.label}
            </CorporateTypography>
          </Box>
        ))}
        trackingData={features.map((feature) => ({
          type: feature.key,
        }))}
        onPageViewed={(pageKey, tracking) => {
          if (!trackedPages.has(pageKey)) {
            trackEvent({
              eventName: "viewed_admin_value_props",
              properties: {
                page_items: tracking.map((t) => t.type),
              },
            });
            trackedPages.add(pageKey);
          }
        }}
      />
    </Box>
  );
};

const FeatureList = () => {
  const classes = useStyles();

  return (
    <Box className={classes.featuresWrapper}>
      {features.map((item) => (
        <Box key={item.key} className={classes.featureContainer}>
          <Box>{item.icon}</Box>
          <Box className={classes.featureLabelContainer}>
            <CorporateTypography variant="subtitle2">
              {item.label}
            </CorporateTypography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export const AdminHomePageSection = ({
  sessionInfo,
}: AdminHomePageSectionProps) => {
  const classes = useStyles();
  const { matchesMobile } = useDeviceTypes();
  const [viewedTracked, setViewedTracked] = useState(false);
  const [hasCompletedWhosBooking, setHasCompletedWhosBooking] = useState(false);
  const isOnboardingRevampAvailable = useExperimentIsVariant(
    "corp-onboarding-revamp",
    "available"
  );
  const isOnboardingRevampDebug = useExperimentIsVariant(
    "corp-onboarding-revamp",
    "debug"
  );
  const isOnboardingRevampEnabled =
    isOnboardingRevampAvailable || isOnboardingRevampDebug;

  const adminCtaDestination = `${CORPORATE_ADMIN_URL}/users${
    hasCompletedWhosBooking ? "" : `?${ONBOARDING_REFERRER_SOURCE}`
  }`;

  const hasSeenModalMap = useSelector(getHasSeenModalMap);

  useEffect(() => {
    setHasCompletedWhosBooking(
      hasSeenModalMap?.[BusinessModalNames.WHOS_BOOKING] ?? false
    );
  }, [!!hasSeenModalMap]);

  if (
    isCaponeTenant(config.TENANT) ||
    (sessionInfo as CorpSessionInfo).corporateInfo.cap1Role !== "PR" ||
    !isOnboardingRevampEnabled
  ) {
    return null;
  }

  const handleCtaClick = () => {
    window.location.href = adminCtaDestination;
  };

  const adminCta = !hasCompletedWhosBooking ? (
    <CorporateButton size="large" onClick={handleCtaClick}>
      Get started
    </CorporateButton>
  ) : (
    <CorporateButton size="large" variant="outlined" onClick={handleCtaClick}>
      View admin settings
    </CorporateButton>
  );

  const adminLabel = !hasCompletedWhosBooking ? (
    <>
      Answer <strong>one quick question</strong> so we can personalize your
      business travel experience.
    </>
  ) : (
    <>Manage upcoming trips and travel spend across your business.</>
  );

  return (
    <InView
      as="div"
      onChange={(inView) => {
        if (inView && !viewedTracked) {
          trackEvent({
            eventName: "viewed_home_page_admin_section",
            properties: {},
          });
          setViewedTracked(true);
        }
      }}
      threshold={0.8}
    >
      <Box className={classes.root}>
        <Box className={classes.container}>
          <Box className={classes.mainTitle}>
            <CorporateTypography
              variant="body2"
              style={{ marginBottom: "18px" }}
            >
              MANAGE YOUR BUSINESS TRAVEL
            </CorporateTypography>

            <CorporateTypography
              variant={matchesMobile ? "h4" : "h2"}
              fontWeight={matchesMobile ? "regular" : "light"}
            >
              Make the most of your business admin settings
            </CorporateTypography>
          </Box>

          <Box className={classes.mainContent}>
            <Box className={classes.panelContentContainer}>
              <Box className={classes.panelContentWrapper}>
                <img
                  className={classes.illustration}
                  src={businessIllustrationbg}
                  alt="admin section illustration"
                />
                <Box className={classes.panelContent}>
                  <CorporateTypography
                    variant={matchesMobile ? "subtitle2" : "h4"}
                    fontWeight={matchesMobile ? "regular" : "light"}
                    className={classes.getStartedText}
                  >
                    {adminLabel}
                  </CorporateTypography>
                  {adminCta}
                </Box>
              </Box>
            </Box>

            <FeatureCarousel itemsPerPage={matchesMobile ? 1 : 2} />
            <FeatureList />
          </Box>
        </Box>
      </Box>
    </InView>
  );
};

export default AdminHomePageSection;
