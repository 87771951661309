import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import config from "../../utils/config";

const SCRIPT_ID = "px-cloud";

export const PXCloudScript = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/book")) {
      if (!document.getElementById(SCRIPT_ID)) {
        const head = document.getElementsByTagName("head")[0];
        const script = document.createElement("script");
        script.src = config.PX_CLOUD_SRC;
        script.id = SCRIPT_ID;
        script.async = true;
        head.appendChild(script);
      }
    }
  }, [location.pathname]);

  return null;
};
