import { useEffect, useMemo, useState } from "react";
import { getUrlWithUtmParams, useUserContext } from "@capone/common";
import { CallState, RewardsAccount } from "redmond";
import { trackEvent } from "../api/v1/trackEvent";
import { useUtmParams } from "../context/userSource";
import config from "../utils/config";

export const useRedirectFromConsumer = (
  rewards: RewardsAccount[],
  fetchRewardsAccountsCallState: CallState
) => {
  const { isBusinessEligible, eligibleBusinessAccounts, businesses } =
    useUserContext("capone");
  const utmParams = useUtmParams();

  const [showCorporateSwitchModal, setShowCorporateSwitchModal] =
    useState(false);

  const cobtRedirectUrl = getUrlWithUtmParams(
    config.corporatePortalUrl || "",
    utmParams
  );

  const isPersonalCard = (reward: RewardsAccount) =>
    !reward.productDisplayName.startsWith("Spark") ||
    reward.accountUseType === "Personal";

  const accountsWithCOBTAccess = useMemo(
    () =>
      new Set(
        businesses
          .filter((business) => "optedIn" in business)
          .map((business) => business.accountReferenceId)
      ),
    [businesses]
  );

  const canAccessConsumer = useMemo(() => {
    // If both rewards and eligible business accounts are empty, cap1 rewards apis might be down -  allow access
    if (!rewards.length && !eligibleBusinessAccounts.length) {
      return true;
    }
    // If there are eligible business accounts but no rewards, deny access
    if (
      (fetchRewardsAccountsCallState === CallState.Failed || !rewards.length) &&
      isBusinessEligible
    ) {
      return false;
    }

    if (fetchRewardsAccountsCallState === CallState.Success) {
      // If any rewards accounts are personal card or not Spark cards (ex VXB), allow access
      if (rewards.some((reward) => isPersonalCard(reward))) {
        return true;
      }

      // Allow access if some rewards accounts do not have a business created
      return rewards.some(
        (account) => !accountsWithCOBTAccess.has(account.accountReferenceId)
      );
    }

    return true;
  }, [
    rewards,
    accountsWithCOBTAccess,
    eligibleBusinessAccounts,
    isPersonalCard,
    isBusinessEligible,
    fetchRewardsAccountsCallState,
  ]);

  useEffect(() => {
    if (!canAccessConsumer) {
      trackEvent({
        eventName: "portal_redirect_to_cobt",
        properties: {},
      }).finally(() => window.location.replace(cobtRedirectUrl));
    } else if (isBusinessEligible) {
      // setShowCorporateSwitchModal(true);
      // note: temporarily disable corporate switch modal
      setShowCorporateSwitchModal(false);
    }
  }, [
    canAccessConsumer,
    accountsWithCOBTAccess,
    cobtRedirectUrl,
    isBusinessEligible,
  ]);

  return { cobtRedirectUrl, canAccessConsumer, showCorporateSwitchModal };
};
